body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiButton-sizeLarge {
  font-size: 50px;
  padding: 0 50px;
}

.container-fluid { 
  max-width: 600px; 
  margin: 0 auto;
} 
.row { margin-bottom: 20px; }  
.btn-lg {    
  font-size: 50px;
}
@media (min-width:600px) {
  .btn-lg {    
    padding: 0 50px;
  }
}
