.MuiButton-sizeLarge {
  font-size: 50px;
  padding: 0 50px;
}

.container-fluid { 
  max-width: 600px; 
  margin: 0 auto;
} 
.row { margin-bottom: 20px; }  
.btn-lg {    
  font-size: 50px;
}
@media (min-width:600px) {
  .btn-lg {    
    padding: 0 50px;
  }
}